import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Space, Drawer, Button, Avatar, message } from "antd";
import { NavLink } from "react-router-dom";
import NavigatorLink from "../navigator-link/navigator-link";
import { DownOutlined } from "@ant-design/icons";
import auth from "../authorization/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkTranslation } from "../../helpers/checkTranslation";
import PropertyModel from "../../data/PropertyModel";
import ProvidersModel from "../../data/ProvidersModel";
import { Authorize } from "../../helpers/Authorize";
import {
  setAssignee,
  setFilteredInfo,
  setPageNumber,
  setPageSize,
  setSortedInfo,
  setTab,
} from "../../redux/Reducer";
import Config from "../../config";
import { UAMDrawer } from "@hcninc/uam-drawer";

const Header = (props) => {
  const adminPortalUrl = Config.getAdminPortalUrl();
  const [profile, setProfile] = useState(false);
  const { userRedux, userPermissions, dineIn } = useSelector((state) => state);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onDrawerClose = () => {
    setProfile(false);
  };

  const handleError = (errorMessage) => {
    console.log("CONSUMER APP SIDE: error caught:", errorMessage);
    message.error(errorMessage);
  };

  const handleLogout = () => {
    if (userRedux) {
      userRedux.permissions.venues && userRedux.permissions.venues.length > 0
        ? userRedux.permissions.venues.map((vn) =>
            ProvidersModel.getById(vn.id)
              .then((res) =>
                ProvidersModel.update(vn.id, {
                  ...res,
                  deviceToken: " ",
                  placeSettings: !!res.placeSettings,
                })
                  .then((res) => {
                    auth.logout(() => {
                      navigate("/");
                    });
                  })
                  .catch((err) => console.log(err.message))
              )
              .catch((err) => {
                console.log(err.message);
              })
          )
        : auth.logout(() => {
            navigate("/");
          });
    }
    window.location.reload(); // Force page reload to clear all component states
    // we need this in case the user passes the OTP successfully but clicks outside the drawer component fast
    // in this case the drawer's state isChangeSuccessfulVisible will not have the chance to get cleared 
  };

  return (
    <div id="page" className="site">
      <header className="site-header site-container">
        <div className="site-header__wrapper">
          <div className="nav-container">
            <NavLink to={adminPortalUrl}>
              <img src="./assets/img/hcn-logo.png" alt="site logo" />
            </NavLink>
            <nav className="site-nav">
              <ul className="site-nav__wrapper">
                <li
                  onClick={() => {
                    dispatch(setTab(dineIn ? 0 : 1));
                    dispatch(setFilteredInfo(null));
                    dispatch(setSortedInfo(null));
                    dispatch(setAssignee(null));
                    dispatch(setPageNumber(1));
                    dispatch(setPageSize(10));
                  }}
                >
                  <NavigatorLink
                    title={checkTranslation("openRequests")}
                    navigateTo="live-orders"
                  />
                </li>
                <li
                  onClick={() => {
                    dispatch(setTab(dineIn ? 0 : 1));
                    dispatch(setFilteredInfo(null));
                    dispatch(setSortedInfo(null));
                    dispatch(setAssignee(null));
                    dispatch(setPageNumber(1));
                    dispatch(setPageSize(10));
                  }}
                >
                  <NavigatorLink
                    title={checkTranslation("archive")}
                    navigateTo="order-history"
                  />
                </li>
                {Authorize.providers.read(userPermissions) && (
                  <li>
                    <NavigatorLink
                      title={checkTranslation("providers")}
                      navigateTo="providers"
                    />
                  </li>
                )}
                {Authorize.users.read(userPermissions) && (
                  <li>
                    <NavigatorLink
                      title={checkTranslation("users")}
                      navigateTo="users"
                    />
                  </li>
                )}
              </ul>
            </nav>
          </div>
          <div className="right-toolbox">
            <div className="site-actions">
              <span
                className="profile"
                id="js-profile"
                onClick={() => setProfile(true)}
              >
                <Avatar
                  size={44}
                  style={{
                    backgroundColor: "#1793C2",
                  }}
                >
                  {" "}
                  <span style={{ fontSize: 25 }}>
                    {userRedux &&
                      `${userRedux?.firstName?.[0]}${userRedux?.lastName?.[0]} `}
                  </span>
                </Avatar>
              </span>
            </div>
            <button
              type="button"
              id="js-nav-toggle"
              className="nav-toggle"
              title="menu"
            >
              <span className="nav-toggle__icon">
                <span className="nav-toggle__line--1"></span>
                <span className="nav-toggle__line--2"></span>
                <span className="nav-toggle__line--3"></span>
              </span>
            </button>
          </div>
        </div>
      </header>
      <UAMDrawer
        onDrawerClose={onDrawerClose}
        isDrawerOpen={profile}
        handleLogout={handleLogout}
        drawerTitle={
          userRedux
            ? `${userRedux.firstName} ${userRedux.lastName}`
            : "User Profile"
        }
        // accountManagementIdleTime={15000} //15 secs for testing
        userProfile={userRedux}
        onError={handleError}
        baseUrl={process.env.REACT_APP_API_PATH_AUTH_BACKEND}
        jwt={localStorage.getItem(Config.LOCAL_DATA_KEYS.JWT_ACCESS_TOKEN)}
      />
    </div>
  );
};

export default Header;
